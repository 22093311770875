/* Add CSS for the container and padding */
.termpolicy {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

/* Style the headings */
h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  margin: 10px 0;
}

/* Style the paragraphs */
p {
  margin: 5px 0;
}

/* Style the links */
a {
  color: #007bff;
  text-decoration: none;
}

/* Style the list items */
ul {
  list-style: none;
  padding-left: 20px;
}

/* Style the list item bullets */
/* ul li:before {
  content: "•";
  margin-right: 5px;
} */

/* Add spacing between list items */
ul li {
  margin-bottom: 5px;
}

/* Add a border to the container */
.termpolicy {
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Center the container */
.termpolicy {
  text-align: left;
}

/* Style the container background */
.termpolicy {
  background-color: #f8f8f8;
}

/* Add a box shadow to the container */
.termpolicy {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Style the header */
.termpolicy h3 {
  font-size: 28px;
  margin-bottom: 20px;
}

/* Style the date */
.termpolicy p:last-child {
  color: #888;
  font-size: 14px;
}

/* Add some spacing to the header */
.termpolicy h1 {
  margin-top: 20px;
  margin-bottom: 10px;
}

/* Style the acknowledgment section */
.ctermpolicy h1:first-of-type {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

/* Style the links in the footer */
.termpolicy ul:last-child a {
  color: #007bff;
  text-decoration: underline;
}

/* Add some spacing to the footer */
.termpolicy ul:last-child {
  margin-top: 20px;
}
