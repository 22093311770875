@import url('https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sanchez:ital@0;1&display=swap');

.bg2 {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

.bg2::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(27, 26, 26, 0.17)), url("../Images/3.jpg");
  filter: blur(1px);
  -webkit-filter: blur(1px);
  background-size: cover;
  background-position: center;
  z-index: -1; /* Move the pseudo-element below the content */
}

.bg2-container {
  position: relative;
  z-index: 1; /* Ensure the text is above the blurred background */
}