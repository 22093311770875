@import url('https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.hero {

    width: 100%;
    height: 100vh;
    color: #fff;
    position: relative;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

#video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.content {
    background-image: url('../Images/bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    position: relative;
    height: 100vh;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin: auto;

    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PreferenceForm_formWrapper__25uHC {
    width: 100%;
    left: 0;
    right: 0;
    font-family: Roboto, sans-serif;
    background: rgba(0, 0, 0, .3);
    border-radius: 3px;
    padding: 1em;
}

.beginbutton {
    width: 70%;
    background: #53b9d2;
    border-color: #53b9d2;
}

.beginbutton:hover {
    width: 70%;
    background: #7fdcf3;
    border-color: #7fdcf3;
}

/* .blurred-background {
    font-family: Roboto, sans-serif;
    background: rgba(0, 0, 0, .3);
    border-radius: 3px;
    padding: 1em;
    display: block;
} */