.cardBG{
    background: linear-gradient(to right, #81ecec, orange);
    clip-path:  polygon(100% 0, 100% 23%, 0 76%, 0 0);;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.card-conatainer{

  height: 100vh;
  width: 100%;
  top: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-conatainer  .card-container-box{
    width:60%;;
    height: calc(100vh - 50px);
   
}

.card-conatainer  .card-container-box  .card-container-box-row{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
   
}

.card-conatainer  .card-container-box  .card-container-box-row  .card-container-box-col{
   width: 30%;
   background-color: #fff;
   height: 500px;
   box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2);
   border-radius: 10px;
}

@media screen and (max-width: 600px) {

    .card-conatainer  .card-container-box{
        width:90%;;
        height: calc(100vh - 50px);
       
    }

    .card-conatainer  .card-container-box  .card-container-box-row  .card-container-box-col{
        width: 48%;
        background-color: #fff;
        height: 500px;
        box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2);
        margin: 5px;
        border-radius: 10px;
     }
     .footCardPlan{
        margin-top: 200px;
     }
     
  }

  @media screen and (max-width: 790px) {

    .card-conatainer  .card-container-box{
        width:90%;;
        height: calc(100vh - 50px);
       
    }

    .card-conatainer  .card-container-box  .card-container-box-row  .card-container-box-col{
        width: 48%;
        background-color: #fff;
        height: 500px;
        box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2);
        margin: 5px;
        border-radius: 10px;
     }
  }

    @media screen and (max-width: 850px) {
 
        .card-conatainer  .card-container-box  .card-container-box-row  .card-container-box-col{
            width: 48%;
            background-color: #fff;
            height: 500px;
            box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2);
            margin: 5px;
            border-radius: 10px;
         }
         .footCardPlan{
            margin-top: 550px;
           
         }

  }


  @media screen and (max-width: 540px) {
    .card-conatainer  .card-container-box  .card-container-box-row  .card-container-box-col{
        width: 45%;
        background-color: #fff;
        height: 500px;
        box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2);
        margin: 5px;
     }
     .footCardPlan{
        margin-top: 550px;
       
     }
  }


@media screen and (max-width: 480px) {
    .card-conatainer  .card-container-box  .card-container-box-row  .card-container-box-col{
        width: 100%;
        background-color: #fff;
        height: 500px;
        box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2);
        margin: 5px;
     }
     .footCardPlan{
        margin-top: 1050px;
       
     }
  }