.window-screen {
    display: block;
    margin-bottom: 5vh;
}

.match-card {
    width: 100%;
    height: 250px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
    margin-top: 20px;
    box-sizing: border-box;
    overflow: hidden;
}

.match-card .match-card-row {
    display: flex;
    justify-content: space-between;
}


.match-card .match-card-row .match-card-col-1 {
    width: 25%;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;

}

.match-card .match-card-row .match-card-col-1 .img-match {
    width: 100%;
    height: 250px;
    border-radius: 20px;
    box-sizing: border-box;
    object-fit: cover;
}

.match-card .match-card-row .match-card-col-1 .stick-match {
    width: 150px;
    height: 150px;
    position: absolute;
    top: -2px;
    left: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.match-card .match-card-row .match-card-col-1 .stick-match::before {
    content: attr(data-before-content);
    position: absolute;
    width: 150%;
    height: 30px;
    background: red;
    transform: rotate(-45deg) translateY(-35px);
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.1em;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);

}

.match-card .match-card-row .match-card-col-1 .stick-match::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: rgb(214, 43, 43);
    bottom: 0;
    left: 0;
    transform: translateY(-28px);
    z-index: -1;
    box-shadow: 140px -140px rgb(180, 67, 67);

}


.match-card .match-card-row .match-card-col-2 {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.match-card .match-card-row .match-card-col-2 .match-des {
    width: 90%;
    cursor: pointer;

}

.match-card .match-card-row .match-card-col-3 {
    width: 25%;

}

.match-card .match-card-row .match-card-col-3 .match-card-connection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;

}

.match-card .match-card-row .match-card-col-3 .match-card-connection .match-card-connection-box {

    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    background-color: #EC4C5C;
    border-radius: 100px;
    cursor: pointer;
}


.mobile-screen {
    display: none;

}

.mobile-screen .mobile-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}


.mobile-match-card {

    width: 48%;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;


}

.mobile-match-card .mobile-match-card-box {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
}


.mobile-match-card .mobile-match-card-box .mobile-match-img {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}

.mobile-match-card .mobile-match-card-box .overlay-mobile {
    width: 100%;
    height: 100%;
    background: linear-gradient(#ffffff00, #29292aec);
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 20px;
    display: flex;
    justify-content: center;
}

.mobile-match-card .mobile-match-card-box .overlay-mobile .mobile-match-desc {
    position: absolute;
    bottom: 10px;
    width: calc(100% - 14px);
    /* background: #fff; */
    height: 140px;
    color: #fff;
    cursor: pointer;
}

.mobile-match-card .mobile-match-card-box .overlay-mobile .mobile-match-desc .mobile-match-desc-row {
    display: flex;
    justify-content: space-between;
    color: #fff;
    cursor: pointer;
}


@media screen and (max-width: 600px) {
    .window-screen {
        display: none;
    }

    .mobile-screen {
        display: block;
    }
}


@media screen and (max-width: 550px) {
    .window-screen {
        display: none;
    }

    .mobile-screen {
        display: block;
    }
}


@media screen and (max-width: 790px) {
    .window-screen {
        display: none;
    }

    .mobile-screen {
        display: block;
    }
}

@media screen and (max-width: 850px) {
    .window-screen {
        display: none;
    }

    .mobile-screen {
        display: block;
    }
}


@media screen and (max-width: 480px) {
    .window-screen {
        display: none;
    }

    .mobile-screen {
        display: block;
    }

    .mobile-match-card {
        width: 100%;
        height: 350px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.Blink {
    animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
}

@keyframes blinker {  
  from { opacity: 1; }
  to { opacity: 0; }
}