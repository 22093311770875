.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: rgb(241, 236, 234);
    border-color: none;
    background-color: #EC4C5B;
    border-radius: 25px 0px;
    transition: background-color 0.3s ease-in-out;
}

ul.nav.nav-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
}

.nav-tabs .nav-link{
    border-bottom: none;
    color: brown;
    font-weight: bold;
   

}

.nav-tabs .nav-link:hover{
    color:'grey';
    opacity: .7;
    background-color: #cccc;
    border-radius: 25px 0px;
}

.Castbox{
    list-style: none;
    border: solid 1px #aaa;
    background-color: #fcfcfc;
    padding: 9px 30px;
    margin: 10px 8px 10px 0;
    border-radius: 18px;
    display: inline-block;
    text-decoration: none;
    letter-spacing: .84px;
    font-size: 14px;
    color: #000;
    font-family: DM Sans;
    
}