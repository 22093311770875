.ProfilesCard{
    border-radius: 20px;
    text-align: center;
    box-shadow: 0 0 15px rgb(231, 219, 219);
}
.ProfileImgCard{
    width: 50px;
    height: 50px;
    border-radius: 50% !important;
}
.btn2 {
  
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
   /* text-shadow: 0px 0px 10px rgba(0,0,0,0.2);*/
    box-shadow: 0 0 20px #eee;
   border: none !important;
   height: 90%;
   width: 50%;
   font-weight: bold;


   }
  
  /* Demo Stuff End -> */
  
  /* <- Magic Stuff Start */
  
  .btn2:hover {
    background-position: right center; /* change the direction of the change here */
  }
  
  .btn-1 {
    background-image: linear-gradient(to right, #f6d365 0%, #fda085 51%, #f6d365 100%);
  }
  .cardScroller{
      width: 100%;
      height: 100%;
     overflow-y: scroll;
  }