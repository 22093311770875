.backabout {
    background-color:#f1f1f1 ;
    background-size: cover;
    -webkit-background-size: cover;
    width: 100%;
     height: 100%;
     
 
  }
  
  
  
  