

.boxIn{
    background:#2f3542 ;
    height:50vh;
  
    clip-path: polygon( 0 0 ,0 100vh,100% 0);
   
    }




    .boxIx2{
   
       border: 1px solid #2f3542;
       border-radius: 20px 0px 20px 0px;
      
        }
       

       
        
    @media only screen and (max-width: 700px) {
        .boxIn  {
            height: 80vh;
            background:#2f3542 ;
            clip-path: polygon( 0 0 ,0 150vh,100% 0);
          
       
    }
      }

      @media only screen and (max-width: 375px) {
        .boxIn  {
            background:#2f3542 ;
            height: 100vh;
          
       
    }
      }