.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    /* background-color: #bbc9cea1; */
    background-color: #edecec51;
    cursor: progress;
    z-index: 111111111111;
}

.loadingBars {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingBar {
    width: 50px;
    height: 10px;
    background-color: #FF5A60;
    border-radius: 10px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25),
    0 1px rgba(255, 255, 255, 0.08);
    margin: 0 5px;
    animation: loadingAnimation 1s infinite ease-in-out, rotateAnimation 3s infinite linear;
}

@keyframes loadingAnimation {
    0% {
        width: 20px;
    }

    50% {
        width: 45px;
    }

    100% {
        width: 20px;
    }
}

@keyframes rotateAnimation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
.none{
    display: none !important;
}