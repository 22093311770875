.login-card {
    margin-top: 8% !important;
    width: 100%;
    height: 55vh;
    border-radius: 10px;
    margin-top: 20px;
    background-color: rgba(240, 248, 255, 0.817);
}

.max-heigth-change{
    height: 70vh;
}

@media screen and (max-width: 480px) {
    .max-heigth-change{
        height: 30vh !important;
    }
    .containersingup {
        background-color: #fff;
        border-radius: 15px;
        padding-left: 20px;
        padding-right: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: 9%;
        box-shadow: none;
    }

    .login-card {
        margin-top: 32% !important;
        width: 100%;
        height: auto;
        border-radius: 10px;
        margin-top: 20px;
        background-color: rgba(240, 248, 255, 0.817);
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .max-heigth-change{
        height: 41vh !important;
    }
   
    .containersingup {
        background-color: #fff;
        border-radius: 15px;
        box-shadow: none;
        padding-left: 20px;
        padding-right: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: 9%;
    }

    .login-card {
        margin-top: 28% !important;
        width: 100%;
        height: 38vh;
        border-radius: 10px;
        margin-top: 20px;
        background-color: rgba(240, 248, 255, 0.817);
    }
}

@media screen and (min-width: 769px) and (max-width: 820px) {
    .login-card {
        height: 55vh;
        border-radius: 10px;
        margin-top: 20px;
    }
    .containersingup {
        background-color: #fff;
        border-radius: 15px;
        box-shadow: none;
        padding-left: 20px;
        padding-right: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        margin-top: 9%;
    }

    .login-card {
        margin-top: 28% !important;
        width: 100%;
        height: 35vh;
        border-radius: 10px;
        margin-top: 20px;
        background-color: rgba(240, 248, 255, 0.817);
    }
}