.profile-container{
    width: 100%;
    height: auto;
    padding: 10px 60px;
    padding-top: 30vh;
}

.inner-card{
    width: 100%;
    height: auto;
    background: linear-gradient(to left top, rgb(155, 151, 151), #C9A078);
    border-radius: 9px;
    position: relative;
}

.profile-container .inner-card .img-box{
    position: absolute;
    top: 0%;
    left: 50%;
    z-index: 1;
    background-color: antiquewhite;
    width: 120px;
    height: 120px;
    border-radius: 255px;
    transform: translate(-50%,-50%);
    border: 2px solid red;
    padding: 1px;
    overflow: hidden;
    object-fit: contain;
}
.profile-container .inner-card .img-box img{
    width: 100%;
    height: 100%;
    border-radius: 255px;
}
.profile-container .inner-card .name{
    padding-top: 13vh;
    color: aliceblue !important;
}
h3{
    color: aliceblue;
}
.contact-item{
    padding: 7px 14px;
    border-radius: 25px;
    background: linear-gradient(to left top, rgb(155, 151, 151), #94c7dd);
    width: fit-content;
    color: black;
    letter-spacing: 1.2px;
}

@media (max-width: 768px) {
    body{
        width: 100vw;
        height: 100%;
    }
    .profile-container {
        padding: 10px 10%;
        padding-top: 22vh;
        width: 100vw;
        height: 100%;
    }

    .profile-container .inner-card .name {
        padding-top: 13vh;
    }

    .footer-details{
        flex-direction: column;
    }
    .contact-item{
        font-size: 12px;
    }
}

@media (min-width: 768px) {
    .profile-container {
        padding: 10px 10%;
        padding-top: 20vh;
        background-color: rgba(255, 0, 0, 0.687) !important;
    }

    .profile-container .inner-card .name {
        padding-top: 8vh;
    }
}

@media (min-width: 1200px) {
    .profile-container {
        padding: 10px 15%;
        padding-top: 30vh;
    }
}

