.headerLink {

    font-family: DM Sans;
    font-size: 15px;
    color: #323232 !important;
    font-weight: 700;
    display: inline-block;
    position: relative;
    text-decoration: none;
    background-color: transparent;
}


.headerLink::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #88C417;
  transition: width .3s;
}
.headerLink:hover::after {
  width: 100%;

}

.Container{
 max-width: 1515px; 
}

/* .headerLink:hover {
  border-bottom: 3px solid red;
} */


/* .navbar {
    -webkit-box-shadow: 0 6px 13px rgb(38 78 118 / 10%);
    -moz-box-shadow: 0 6px 13px rgb(38 78 118 / 10%);
    box-shadow: 0 6px 13px rgb(38 78 118 / 10%);
  } */
  
 