.vLine {
  margin-top: 10px;
  border-left: 2px solid rgb(89, 150, 89);
  height: 280px;
}

@media only screen and (max-width: 600px) {
  .vLine {
    display: none !important;
  }
}

.inputtype {
  background: transparent;
  border: 2px solid #000000;
  margin: 10px;
  border-radius: 20px;
  color: rgb(20, 11, 11);
  padding-left: 20px;
  width: 80%;

}

.button {
  font-size: 15px;
  margin-top: 10px;
  width: 70%;
  background-color: #ff5a60;
}

.custom-list {
  list-style-type: none;
  padding: 0;
  font-family: Arial, sans-serif;
}

/* Animation for hover effect */
.custom-list li {
  color: black;
  margin-top: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.custom-list li:hover {
  transform-origin: left;
  animation: getUp 7s infinite;
}

@keyframes getUp {
  10%, 50% {
    transform: rotateX(0);
    color: #aaf;
  }
  0%, 60%, 100% {
    transform: rotateX(-85deg);
    color: #EC4C5C;
  }
}