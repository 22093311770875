.matchingBG{
    background: linear-gradient(to right,#EC4C5C , rgb(211, 203, 203));
    height: 100vh;
  
}

.matchBox{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

}

 .boxProfile{
    width:90%;
    height: calc(100vh - 50px);
    overflow: scroll;
    
}

.boxProfile .grid-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
   
}

.boxProfile .grid-container .grid-center{
  width: 33%;
   display:flex;
   align-items: center;
   justify-content: center;
  



}

.boxProfile .grid-container .grid-center .grid-item{
 width: 350px;
 height: 150px;
 background-color: rgba(255, 255, 255,0.5);
 margin: 10px;
 display: flex;
 box-shadow: 2px 2px 2px 2px rgba(0, 0, 0,0.2);
 transition: 5s linear;
  border-radius: 10px;
  border: 2px solid #EC4C5C;
  cursor: pointer;

}


.boxProfile .grid-container .grid-center .grid-item:hover{

  
   
   
   }
   
.boxProfile .grid-container .grid-item .grid-item-img{
width: 50%;
display: flex;
height: 100%;
align-items: center;


}

.boxProfile .grid-container .grid-item .grid-item-img .imgbox-item{
  
    border-radius: 10px;
}

.boxProfile .grid-container .grid-item .grid-item-des{
width: 50%;
height:100% ;
display: flex;
align-items:center;
justify-content: center;


}



.boxProfile .grid-container .grid-item .grid-item-des .grid-item-des-box{
width: 70%;  



}

.boxProfile .grid-container .grid-item .grid-item-des p{
font-size: 10px;
    }



@media screen and (max-width: 600px) {
    .boxProfile{
        width: 95%;
        height: calc(100vh - 100px);
        overflow: scroll;
    }
   
.boxProfile .grid-container .grid-center{
   width: 100%;
   display:flex;
   align-items: center;
   justify-content: center; 
}


  }


  @media screen and (max-width: 550px) {
    .boxProfile{
        width: 95%;
        height: calc(100vh - 100px);
        overflow: scroll;
    }
   
.boxProfile .grid-container .grid-center{
   width: 120%;
   display:flex;
   align-items: center;
   justify-content: center; 
}


  }


  @media screen and (max-width: 790px) {
    .boxProfile{
        width: 95%;
        height: calc(100vh - 100px);
        overflow: scroll;
    }
   
.boxProfile .grid-container .grid-center{

   width: 50%;
   display:flex;
   align-items: center;
   justify-content: center; 
}


  }

    @media screen and (max-width: 850px) {
    .boxProfile{
        width: 95%;
        height: calc(100vh - 100px);
        overflow: scroll;
    }
   
.boxProfile .grid-container .grid-center{
   width: 50%;
   display:flex;
   align-items: center;
   justify-content: center; 
}


  }


@media screen and (max-width: 480px) {
    .boxProfile{
        width: 95%;
        height: calc(100vh - 100px);
        overflow: scroll;
    }

    .boxProfile .grid-container .grid-center{
       width: 100%;
       display:flex;
       align-items: center;
       justify-content: center; 
    }
  }