 
.btn-sub{
   width: 120px;
    background:#fff ;
    box-shadow: 5px 0px 5px 5px rgba(0, 0, 0, 0.2);
    margin-left: 40px;
    color: black;
    margin-top: 10px;
    border-radius: 50px;
    border:none;



}
.btn-sub:hover{

    background: #0097e6,

}
