@import url('https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.Couplrcard{
   display: flex;
   justify-content: center;
   align-items: center;
 
   
}
.Couplrcard:hover{
  
  
}