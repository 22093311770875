@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  width: 100%;
  height: 100%;
}

.cont {
  font-family: 'Poppins', sans-serif;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: auto;
  flex-wrap: wrap;
}
img {
  max-width: 100%;
  display: block;
}
ul {
  list-style: none;
}

/* Utilities */
.card-profile::after,
.card-profile img {
  border-radius: 50%;
}
.cont,
.card-profile,
.stats {
  display: flex;
}

.card-profile {
  padding: 2.5rem 2rem;
  border-radius: 10px;
  background-color: #D6F2FC;
  max-width: 500px;
  box-shadow: 0 0 30px rgba(0, 0, 0, .15);
  margin: 1rem;
  position: relative;
  transform-style: preserve-3d;
  overflow: hidden;
}
.card-profile::before,
.card-profile::after {
  content: '';
  position: absolute;
  z-index: -1;
}
.card-profile::before {
  width: 100%;
  height: 100%;
  border: 1px solid #FFF;
  border-radius: 10px;
  top: -.7rem;
  left: -.7rem;
}
.card-profile::after {
  height: 15rem;
  width: 15rem;
  background-color: #4172f5aa;
  top: -8rem;
  right: -8rem;
  box-shadow: 2rem 6rem 0 -3rem #FFF
}

.card-profile img {
  width: 5rem;
  height: 5rem;
  min-width: 80px;
  box-shadow: 0 0 0 5px #FFF;
  object-fit: contain;
}

.infos {
  margin-left: 1.5rem;
}

.name {
  margin-bottom: 1rem;
}
.name h2 {
  font-size: 1.3rem;
}
.name h4 {
  font-size: .8rem;
  color: #333
}

.text {
  font-size: .9rem;
  margin-bottom: 1rem;
}

.stats {
  margin-bottom: 1rem;
}
.stats li {
  min-width: 5rem;
}
.stats li h3 {
  font-size: .99rem;
}
.stats li h4 {
  font-size: .75rem;
}

.links button {
  font-family: 'Poppins', sans-serif;
  min-width: 120px;
  padding: .5rem;
  border: 1px solid #222;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: all .25s linear;
}
.links .follow,
.links .view:hover {
  background-color: #222;
  color: #FFF;
}
.links .view,
.links .follow:hover{
  background-color: transparent;
  color: #222;
}

@media screen and (max-width: 450px) {
  .card-profile {
    display: block;
  }
  .infos {
    margin-left: 0;
    margin-top: 3.5rem;
  }
  .links button {
    min-width: 100px;
  }
}
