@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

#card{
    background: yellow;

    }


@media only screen and (max-width: 768px) {
    
    

    .img{

        width: 50px;
    }
    
  }