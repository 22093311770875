/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.containersingup{
    background-color:#fff;
    border-radius: 15px;
    box-shadow: 0 0 10px;
    padding-left: 20px;
    padding-right: 20px;
    background-image: url('../../Images/liquid-artiste-SUwPo4ErQCc-unsplash.jpg');
    background-repeat: no-repeat; 
    background-size: cover;
}

.InputBoxDesign{
   
    font-family: Lato;
    font-size: 16px;
    color: #151b39;
    border-top: none !important;
    border-bottom: 2px solid #24aeb1 ;
}
.InputBoxDesign2{
   
    font-family: Lato;
    font-size: 16px;
    color: #24aeb1;
    font-weight: bold;
    border-top: none !important;
    border-bottom: 2px solid #24aeb1 ;
    background-color: #fff;
}

.OTPbutton {
    box-sizing: border-box;
    margin: 0;
    min-width: 50%;
    height: 50%;
    display: inline-block;
    text-decoration: none;
    font-size: 15px;
    color: #fff;
    background-color:#2B97A4 ;
    border: 0;
    font-family: DM Sans;
    font-weight: 0;
    border-radius: 25px;
    cursor: pointer;
   text-align: center;
  }
  
  .OTPbutton:hover {
  background-color:#051646 ;
  }
  .OTPbutton:active {
  background-color: #020718;
  }
  .OTPbutton:focus {
  background-color: #020718;
  outline: none !important;
  box-shadow: none !important;
  }


  .bgWhite{
      width: "400px";
    
  }
  
  .title{
    font-weight:600;
    margin-top:5px;
    font-size:24px
  }
  
  .customBtn{
    border-radius:0px;
    padding:10px;
  }
  
  form .otp{
    display:inline-flex;
    width:50px;
    height:50px;
    text-align: center;
  }