.porfileBG {
  background-color: #dfe5e6;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}

.porfileBG .profile-container1 {
  width: 60%;
  background: #ffffff;
}

.porfileBG .profile-container1 .profile-detail {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.porfileBG .profile-container1 .profile-detail {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.porfileBG .profile-container1 .profile-detail .profile-detail-row {
  width: 98%;
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.porfileBG .profile-container1 .profile-detail .profile-img {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.porfileBG .profile-container1 .profile-detail .profile-des {
  width: 70%;
}

.porfileBG .profile-container1 .profile-detail .profile-des .profile-des-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.porfileBG
  .profile-container1
  .profile-detail
  .profile-des
  .profile-des-row
  .profile-des-col {
  width: 49%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.porfileBG .profile-container1 .profile-detail .profile-des .profile-manage {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

li::marker {
  color: red;
}

.profile-btn {
  margin-top: 10px;
  padding: 10px;
  border: none;
  font-size: 12px;
  font-weight: 600;
}

.profile-btn:hover {
  margin-top: 10px;
  padding: 10px;
  border: none;
  font-size: 12px;
  font-weight: 600;
  background-color: aqua;
  color: #fff;
}

.about-other {
  margin-top: 20px;
}

.about-other .about-other-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.about-other .about-other-row .about-other-col {
  width: 49%;
}

@media screen and (max-width: 600px) {
  .porfileBG .profile-container1 {
    width: 90%;
    height: 100vh;
    background: #ffffff;
  }

  .porfileBG
    .profile-container1
    .profile-detail
    .profile-des
    .profile-des-row
    .profile-des-col {
    width: 100%;

    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .about-other {
    margin-top: 220px;
  }

  .about-other .about-other-row .about-other-col {
    width: 100%;
    background-color: aqua;
  }
}

@media screen and (max-width: 550px) {
  .porfileBG .profile-container1 {
    width: 90%;
    height: 100vh;
    background: #ffffff;
  }

  .porfileBG .profile-container1 .profile-detail .profile-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .porfileBG .profile-container1 .profile-detail .profile-des {
    width: 100%;
    height: 300px;
  }

  .porfileBG .profile-container1 .profile-detail .profile-des .profile-manage {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .about-other .about-other-row .about-other-col {
    width: 100%;
    background-color: aqua;
  }
}

@media screen and (max-width: 790px) {
  .porfileBG .profile-container1 {
    width: 90%;
    height: 100vh;
    background: #ffffff;
  }

  .porfileBG .profile-container1 .profile-detail .profile-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .porfileBG .profile-container1 .profile-detail .profile-des {
    width: 100%;
    height: 300px;
  }

  .porfileBG .profile-container1 .profile-detail .profile-des .profile-manage {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .about-other .about-other-row .about-other-col {
    width: 100%;
    background-color: aqua;
  }
}

@media screen and (max-width: 850px) {
  .porfileBG .profile-container1 {
    width: 90%;
    height: auto;
    background: #ffffff;
  }

  .porfileBG .profile-container1 .profile-detail .profile-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .porfileBG .profile-container1 .profile-detail .profile-des {
    width: 100%;
    height: 300px;
  }

  .porfileBG .profile-container1 .profile-detail .profile-des .profile-manage {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .about-other .about-other-row .about-other-col {
    width: 100%;
    background-color: aqua;
  }
}

@media screen and (max-width: 480px) {
  .porfileBG .profile-container1 {
    width: 90%;
    height: 100vh;
    background: #ffffff;
  }

  .porfileBG .profile-container1 .profile-detail .profile-img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .porfileBG .profile-container1 .profile-detail .profile-des {
    width: 100%;
    height: 300px;
  }

  .porfileBG .profile-container1 .profile-detail .profile-des .profile-manage {
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .about-other .about-other-row .about-other-col {
    width: 100%;
    background-color: aqua;
  }
}
