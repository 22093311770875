.adminlogin-card{
    height: 60vh;
    border-radius: 10px;
    width: 90%;
}

@media screen and (max-width: 480px) {
    .adminlogin-card{
        height: 45vh;
        border-radius: 10px;
       width: 90%;
    }
  }