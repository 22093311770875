.sidebarContainer {
    margin-top: 80px;
    background-color: #eee;
    padding: 15px;
}

.leftCard{
    margin-top: 12px;
    margin-bottom: 12px;
}

.rightCard{
    margin-top: 12px;
    margin-bottom: 12px;
}

.image-container{
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-container .image-box{
    width: fit-content;
    position: relative;
}


.image-container .image-box .edit-button{
    width: 100px;
    height: 100px;
    background-color: rgba(225, 162, 162, 0.461);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    display: none;
    transition: all 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    >svg{
        color: #fff;
    }
}

.image-container .image-box:hover .edit-button{
    display: flex;
}

.ProfileImg{
    width: 150px;
    height: 150px;
    object-fit: contain;
    border-radius: 50% !important;
}

.user-not-click{
    cursor: none !important;
    user-select: none;
}