/* PrivacyPolicy.css */

.privacy-policy {
    padding: 20px;
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  h3 {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  p {
    color: #555;
    line-height: 1.6;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  