/* Styling for the container */
.canpolicy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  /* Styling for the headings */
  h1 {
    font-size: 28px;
    margin-bottom: 16px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 12px;
  }
  
  /* Styling for paragraphs and links */
  p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 12px;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  /* Styling for lists */
  ul {
    margin-bottom: 12px;
    padding-left: 20px;
  }
  
  /* Styling for list items */
  li {
    font-size: 16px;
    line-height: 1.6;
  }
  
  /* Styling for the contact info */
  ul li:last-child {
    margin-bottom: 0;
  }
  
  /* Styling for the container holding the contact info */
  ul:last-child {
    margin-top: 16px;
    border-top: 1px solid #ccc;
    padding-top: 12px;
  }
  
  /* Additional styling for the gift section */
  h1 + p {
    margin-top: 0;
  }
  
  /* Styling for the gift section */
  h1 + p + p {
    margin-top: 12px;
  }
  
  /* Styling for the main headings and links in the gift section */
  h1 + p + p + h2,
  h1 + p + p + p + h2 {
    margin-top: 24px;
  }
  